import { BlitzLayout } from "@blitzjs/next"
import {
  Box,
  Button,
  ButtonGroup,
  Container,
  Heading,
  HStack,
  Icon,
  Skeleton,
  Stack,
  Text,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react"
import { isAdmin } from "app/commonUtils"
import Head from "next/head"
import Link from "next/link"
import { useRouter } from "next/router"
import { UserContext } from "pages/_app"
import React, { Suspense, useContext, useEffect } from "react"
import {
  IoChatbubble,
  IoChatbubbleEllipses,
  IoJournal,
  IoLogoInstagram,
  IoLogoTwitter,
} from "react-icons/io5"
import { RiUserSmileFill } from "react-icons/ri"
import { SiSpotify, SiStrava } from "react-icons/si"
import { accountsFromUser, AuthButton, isUserSetup } from "../components/AuthButtons"
import { GetStartedButton, TermsAndPrivacy, WorksWith } from "../components/HomeItems"
import InviteModal from "../components/InviteModal"

// type Disclosure = ReturnType<typeof useDisclosure>

const Layout: BlitzLayout<{
  title?: string
  description?: string
  image?: string
  children?: React.ReactNode
  youLink?: boolean
  wide?: boolean
  showBottomBanner?: boolean
}> = ({ title, description, image, children, youLink = true, wide, showBottomBanner = false }) => {
  const { user, isFetched } = useContext(UserContext)
  const { strava, spotify } = accountsFromUser(user)
  const isSetup = isUserSetup(user)

  const backgroundColor = useColorModeValue("rgba(255,255,255,0.8)", "rgba(26,32,44,0.8)")

  const router = useRouter()
  const toast = useToast()

  useEffect(() => {
    if (router.query.authError) {
      toast({
        title: "Uh oh!",
        description: router.query.authError as string,
        status: "error",
        duration: 12000,
      })
      router
        .replace({
          pathname: router.pathname,
          query: {},
        })
        .catch(() => {})
    }
  }, [router, router.query.authError, toast])

  return (
    <>
      <Head>
        <title>{(title ? title + " | " : "") + "Spava.club - Spotify + Strava"}</title>
        <link rel="icon" type="image/png" href="/favicon.png" />
        <link rel="apple-touch-icon" href="/logo.png" />
        <meta
          property="og:description"
          content={description ?? "Catalogue and share the music you play during your activities."}
        />
        <meta property="og:image" content={image} />
      </Head>

      <Container
        maxW={wide ? "4xl" : "2xl"}
        pt={4}
        pb={7}
        px={3}
        position="relative"
        display="flex"
        flexDirection="column"
        minHeight="100vh"
      >
        <HStack>
          <Heading fontSize="4xl" letterSpacing={-1}>
            <Link href="/" passHref>
              <a>
                <HStack>
                  <svg
                    width="35"
                    // height="600"
                    viewBox="0 0 600 600"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M322.614 546H83V507H316.014C330.952 507 344.121 503.575 355.248 496.454L355.319 496.409L355.388 496.362C366.165 489.033 374.629 479.714 380.697 468.445C386.759 457.187 389.814 445.212 389.814 432.6C389.814 419.672 386.797 407.697 380.718 396.791C375.028 385.469 366.698 376.483 355.823 369.928C345.067 362.797 332.088 359.4 317.214 359.4H238.014C215.264 359.4 195.477 354.783 178.483 345.73C161.42 336.241 148.023 323.401 138.191 307.165C128.775 290.564 124.014 271.824 124.014 250.8C124.014 229.305 128.608 210.106 137.684 193.069C147.163 176.024 160.193 162.624 176.841 152.781C193.82 142.977 213.354 138 235.614 138H541V177H241.014C226.832 177 214.107 180.445 203.053 187.499C192.188 194.036 183.83 202.814 178.114 213.787C172.442 224.275 169.614 235.646 169.614 247.8C169.614 259.524 172.227 270.809 177.405 281.596L177.468 281.729L177.538 281.858C183.251 292.404 191.388 300.959 201.834 307.488L201.88 307.517L201.927 307.545C212.949 314.158 225.436 317.4 239.214 317.4H320.214C344.621 317.4 365.17 322.24 382.1 331.645C399.171 341.129 412.144 353.944 421.175 370.124C430.22 386.33 434.814 404.902 434.814 426C434.814 449.559 429.993 470.388 420.493 488.628L420.481 488.65L420.47 488.671C411.378 506.476 398.358 520.437 381.327 530.655C364.356 540.838 344.844 546 322.614 546Z"
                      fill="#717171"
                      stroke="black"
                      strokeWidth="12"
                    />
                    <path
                      d="M402.614 462H58V423H396.014C410.952 423 424.121 419.575 435.248 412.454L435.319 412.409L435.388 412.362C446.165 405.033 454.629 395.714 460.697 384.445C466.759 373.187 469.814 361.212 469.814 348.6C469.814 335.672 466.797 323.697 460.718 312.791C455.028 301.469 446.698 292.483 435.823 285.928C425.067 278.797 412.088 275.4 397.214 275.4H318.014C295.264 275.4 275.477 270.783 258.483 261.73C241.42 252.241 228.023 239.4 218.19 223.165C208.775 206.563 204.014 187.824 204.014 166.8C204.014 145.306 208.608 126.106 217.684 109.069C227.163 92.0241 240.193 78.6238 256.842 68.7803C273.82 58.9766 293.354 54 315.614 54H514.814V93H321.014C306.832 93 294.107 96.4454 283.053 103.5C272.188 110.036 263.831 118.814 258.114 129.787C252.442 140.275 249.614 151.646 249.614 163.8C249.614 175.524 252.227 186.809 257.405 197.596L257.468 197.729L257.538 197.858C263.251 208.404 271.388 216.959 281.834 223.488L281.88 223.517L281.927 223.545C292.949 230.158 305.436 233.4 319.214 233.4H400.214C424.621 233.4 445.17 238.24 462.1 247.645C479.171 257.129 492.144 269.944 501.175 286.124C510.22 302.33 514.814 320.902 514.814 342C514.814 365.559 509.993 386.388 500.493 404.628L500.481 404.65L500.47 404.671C491.378 422.476 478.358 436.437 461.327 446.655C444.356 456.838 424.844 462 402.614 462Z"
                      fill="white"
                      stroke="black"
                      strokeWidth="12"
                    />
                  </svg>
                  <Text> Spava</Text>
                </HStack>
              </a>
            </Link>
          </Heading>
          <Box flex="1"></Box>
          𐤟
          {isFetched && (
            <>
              {router.pathname !== "/" && (
                <Link href="/" passHref>
                  <Button as="a" size="sm" variant="outline">
                    {user ? "Feed" : "Home"}
                  </Button>
                </Link>
              )}

              {!user && router.pathname !== "/feed" && (
                <Link href="/feed" passHref>
                  <Button as="a" size="sm" variant="outline">
                    Feed
                  </Button>
                </Link>
              )}

              {!!user && isAdmin(user.role) && (
                <Link href="/admin" passHref>
                  <Button as="a" size="sm" variant="outline">
                    Admin
                  </Button>
                </Link>
              )}

              {youLink && !!user && (
                <Link href="/settings" passHref>
                  <Button
                    as="a"
                    size="sm"
                    variant={"outline"}
                    rightIcon={user?.playlist ? <SiSpotify /> : undefined}
                  >
                    You
                  </Button>
                </Link>
              )}

              {!user && (
                <Link href="/api/auth/spotify" passHref>
                  <Button as="a" rel="nofollow" size="sm" variant="solid" colorScheme="green">
                    Join
                  </Button>
                </Link>
              )}
            </>
          )}
        </HStack>

        {user && (
          <HStack fontSize="xs" opacity={0.8} letterSpacing={-0.5}>
            {/* <Text fontWeight="light">{userCount} users strong</Text>
            <Text>⸱</Text> */}
            <Box>
              <HStack
                fontWeight="medium"
                color="blue.500"
                textDecoration="underline"
                _hover={{ textDecoration: "none" }}
                cursor="pointer"
                spacing={1}
              >
                <Icon as={RiUserSmileFill} color="blue.500" />
                <InviteModal>
                  <Text>Bring Friends</Text>
                </InviteModal>
              </HStack>
            </Box>
          </HStack>
        )}

        <Box mt={6} flex="1">
          <Suspense fallback={<SingleSuspense />}>
            {!isFetched ? (
              <SingleSuspense />
            ) : (
              <>
                {children}
                {!isSetup && showBottomBanner && (
                  <Box
                    w="full"
                    position="fixed"
                    zIndex={150}
                    bottom={0}
                    left={0}
                    bg={backgroundColor}
                    p={5}
                    pt={3}
                    backdropFilter="blur(4px)"
                    borderTop="solid 1px"
                    borderTopColor="gray.500"
                  >
                    <Box w="full">
                      {!spotify ? (
                        <>
                          <Text textAlign="center" mb={2} fontSize="md" fontWeight="medium">
                            Begin cataloging your workout music by connecting Spotify.
                          </Text>
                          <GetStartedButton />
                        </>
                      ) : (
                        <>
                          <Text textAlign="center" mb={2} fontSize="md" fontWeight="medium">
                            Begin cataloging your workout music by connecting Strava.
                          </Text>
                          <AuthButton account={strava} provider="strava" />
                        </>
                      )}
                    </Box>
                  </Box>
                )}
              </>
            )}
          </Suspense>
        </Box>

        <Footer />

        {!isSetup && showBottomBanner && <Box pb={32}></Box>}
      </Container>
    </>
  )
}

export const SingleSuspense = () => <Skeleton height={60} opacity={0.4} />

export const FeedSuspense = () => (
  <Stack spacing={10} opacity={0.4}>
    <Skeleton borderRadius={5} height="430px" />
    <Skeleton borderRadius={5} height="430px" />
    <Skeleton borderRadius={5} height="430px" />
  </Stack>
)

const Footer = () => {
  return (
    <Box as="footer" pt={20} alignSelf="flex-end" w="full">
      {/* <Divider /> */}
      {/* <WorksWith /> */}
      <Text fontSize="sm" mt={2} textAlign="center" color="gray.500">
        Connect with us:
      </Text>
      <ButtonGroup isAttached width="full" my={2} size="sm" variant="outline">
        <Button
          flex="1"
          as="a"
          href="mailto:support@spava.club"
          title="Email"
          borderRight={0}
          borderRightRadius={0}
        >
          <IoChatbubbleEllipses /> &nbsp;Support
        </Button>
        <Button
          flex="1"
          as="a"
          href="https://www.strava.com/clubs/spava"
          target="_blank"
          title="Join our Strava Club"
          borderLeftRadius={0}
        >
          <SiStrava /> &nbsp;Club
        </Button>
        <Button flex="1" as="a" href="https://blog.spava.club/en" target="_blank" title="Our Blog">
          <IoJournal /> &nbsp;Blog
        </Button>
      </ButtonGroup>

      <Text fontSize="sm" mt={6} textAlign="center" color="gray.500">
        Follow us:
      </Text>
      <ButtonGroup isAttached width="full" my={1} size="sm" variant="outline">
        <Button
          flex="1"
          as="a"
          href="https://instagram.com/spava.club"
          target="_blank"
          title="Follow us on Instagram"
        >
          <IoLogoInstagram /> &nbsp;Instagram
        </Button>
        <Button
          flex="1"
          as="a"
          href="https://twitter.com/SpavaClub"
          target="_blank"
          title="Follow us on Twitter"
        >
          <IoLogoTwitter /> &nbsp;Twitter
        </Button>
      </ButtonGroup>

      <Text fontSize="sm" mt={8} textAlign="center" color="gray.500">
        Spava <TermsAndPrivacy />
      </Text>
      <Text fontSize="sm" mt={8} textAlign="center" color="gray.500">
        A{" "}
        <strong>
          <a href="https://instagram.com/crohanmoss" target="_blank" rel="noreferrer">
            cro
          </a>
          <a href="https://instagram.com/rorhug" target="_blank" rel="noreferrer">
            hug
          </a>
        </strong>{" "}
        production. 🇮🇪
      </Text>
    </Box>
  )
}

export default Layout
