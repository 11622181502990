import {
  Box,
  BoxProps,
  Button,
  ButtonProps,
  Divider,
  Grid,
  GridItem,
  Heading,
  HStack,
  Icon,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react"
import Link from "next/link"
import { SiSpotify, SiStrava } from "react-icons/si"
import { FaBiking, FaPlus, FaPlusCircle, FaRunning, FaSkiing, FaSnowboarding } from "react-icons/fa"
import { IoArrowForwardCircleSharp, IoAdd } from "react-icons/io5"
import { MdGolfCourse, MdKayaking, MdNordicWalking } from "react-icons/md"
import { GiSoccerBall, GiWeightLiftingUp } from "react-icons/gi"
import { ReactNode } from "react"
import { IconType } from "react-icons"

export const WorksWith = (props: BoxProps) => (
  <HStack spacing={1} {...props} justifyContent="center" fontSize="sm">
    <Text fontStyle="italic" fontWeight="medium" opacity={0.8}>
      Spava works with
    </Text>
    <Icon as={SiSpotify} color="green" />
    <Text as="span" color="green" fontWeight="bold">
      Spotify
    </Text>
    <Icon as={IoAdd} my="8px" />
    <Icon as={SiStrava} fontSize="1.2em" color="#FC4C02" />
    <Text color="#FC4C02" fontWeight="bold">
      Strava
    </Text>
  </HStack>
)

export const TermsAndPrivacy = () => (
  <>
    <Link href="/terms" passHref>
      <Text as="a" textDecoration="underline">
        terms of service
      </Text>
    </Link>{" "}
    and{" "}
    <Link href="/privacy" passHref>
      <Text as="a" textDecoration="underline">
        privacy policy
      </Text>
    </Link>
    .
  </>
)

export const GetStartedButton = (props: BoxProps) => (
  <Box {...props}>
    <Text color="gray.500" fontSize={12} textAlign="center" mb={3}>
      By continuing, you agree to the <TermsAndPrivacy />
    </Text>
    <Link href="/api/auth/spotify" passHref>
      <Button
        rel="nofollow"
        as="a"
        variant="solid"
        colorScheme="green"
        w="full"
        size="lg"
        fontSize={24}
        rightIcon={<IoArrowForwardCircleSharp />}
        leftIcon={<SiSpotify />}
      >
        Get Started
      </Button>
    </Link>
  </Box>
)

export const DividerWithText = ({ children, ...rest }: { children: ReactNode } & BoxProps) => {
  const thenBackground = useColorModeValue("white", "gray.800")

  return (
    <Box position="relative" py={3} {...rest}>
      <Divider />
      <Box position="absolute" top={0} display="flex" justifyContent="center" width="full">
        <Text bg={thenBackground} px={3} fontSize="md" color="gray.500" fontWeight="medium">
          {children}
        </Text>
      </Box>
    </Box>
  )
}

const Sport = ({ name, icon }: { name: string; icon: IconType }) => (
  <Box textAlign="center" py={7} px={7} borderColor="gray.500">
    <Icon as={icon} fontSize={64} my={1} />
    <Text fontSize={22} textAlign="center">
      {name}
    </Text>
  </Box>
)

export const Sports = () => {
  return (
    <>
      <Heading as="h3" mb={4} fontWeight="semibold" letterSpacing="tight">
        Spava works with all sports:
      </Heading>
      <HStack overflowX="scroll" w="full">
        <Sport icon={FaRunning} name="Run" />
        <Sport icon={FaBiking} name="Bike" />
        <Sport icon={MdNordicWalking} name="Hike" />
        <Sport icon={MdKayaking} name="Kayak" />
        <Sport icon={FaSkiing} name="Ski" />
        <Sport icon={FaSnowboarding} name="Snowboard" />
        <Sport icon={MdGolfCourse} name="Golf" />
        <Sport icon={GiSoccerBall} name="Football" />
        <Sport icon={GiWeightLiftingUp} name="Gym" />
      </HStack>

      <Text color="gray.500" textAlign="center">
        ...or any sport{" "}
        <Text
          as="a"
          href="https://support.strava.com/hc/en-us/articles/216919407-Supported-Sport-Types-on-Strava"
          target="_blank"
          rel="noreferrer"
          textDecoration="underline"
          _hover={{ textDecoration: "none" }}
        >
          supported by Strava
        </Text>
        .
      </Text>
    </>
  )
}

// CgGym
// GiWeightLiftingUp
